import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import { Card } from "react-bootstrap"
import EntryTitle from "../components/entry-title"

const EntryListCard = ({ frontmatter, fields, excerpt }) => {
  let thumbnailEl;
  if(frontmatter.thumbnail){
    thumbnailEl = (<Image fixed={frontmatter.thumbnail.childImageSharp.fixed} />)
  }else{
    thumbnailEl = (<div>No Image</div>)
  }
  return (
    <Card className="mb-4 entry">
      <Card.Body>
        <EntryTitle 
          frontmatter={frontmatter}
          fields={fields}
        />
        <section className="entry-box">
          <div className="img-box">{thumbnailEl}</div>
          <p
            className="text_box"
            dangerouslySetInnerHTML={{
              __html: frontmatter.description || excerpt,
            }}
          />
        </section>
        <div className="read-button"><p>
          <Link to={fields.slug} className="btn btn-primary">
            続きを読む &rarr;
          </Link>
        </p></div>

      </Card.Body>
    </Card>
  )
}

export default EntryListCard