import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { Container } from "react-bootstrap"
import Layout from '../components/layout'
import EntryList from "../components/entry-list"


const ArchiveMonthlyTemplate = ({data, pageContext, location }) => {
  const title = data.site.siteMetadata.title
  const { displayMonth, displayYear } = pageContext

  return (
    <Layout location={location} title={title}>
      <div>
        <div className="category-container">
          <Container>
            <Helmet title={`${displayYear}年${displayMonth}月の記事 - ${title}`} />
            <EntryList postEdges={data.allMarkdownRemark.edges} />
          </Container>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PeriodPage($periodStartDate: Date, $periodEndDate: Date) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: {
        frontmatter: {
          date: { gte: $periodStartDate, lt: $periodEndDate }
        }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
            category
            tags
          }
          frontmatter {
            date(formatString: "YYYY/MM/DD")
            title
            description
            category
            tags
            thumbnail {
              childImageSharp {
                fixed(width: 168, height:168) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ArchiveMonthlyTemplate
