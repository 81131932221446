import React from "react"
import { Link } from "gatsby"

const EntryTitle = ({frontmatter, fields}) => {
    return (
        <header>
            <h1>
            <Link to={fields.slug}>
                {frontmatter.title}
            </Link>
            </h1>
            <hr />
            <div className="entry-option">
                <ul>
                <li className={`Category Category_${frontmatter.category}`}>
                    <a href={`/category/${frontmatter.category}/`}>{frontmatter.category}</a>
                </li>
                <li className="time">{frontmatter.date}</li>
                </ul>
            </div>
        </header>
    )
}

export default EntryTitle
